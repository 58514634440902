import { css } from 'lit';

export const appFooterStyles = css`
  :host {
    display: block;
    margin: 0 auto;
    padding: 4rem 2rem 5rem 2rem;
    
    box-sizing: content-box;
    max-width: 800px;

    color: var(--text-color);

    /* Default Colors */
    --text-color: var(--gray-7);
    --text-color-focus: var(--gray-9);
  }
  :host([variant='white-text']) {
    --text-color: white;
    --text-color-focus: var(--gray-3);
  }

  footer-inner {
    display: block;
    position: relative;
    margin: 0;
    padding: 1.5rem 0 0 0;

    border-top: 1px solid var(--text-color);
  }
  @media(min-width: 650px) {
    footer-inner {
      padding-top: 2rem;
    }
  }

  #social-links {
    position: absolute;
    right: 0rem;
    top: 1.5rem;
    height: 2rem; 
    font-size: 1.3rem;
    
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    row-gap: 0.15rem;

    max-width: 7rem;
  }
  @media(min-width: 500px) {
    #social-links {
      max-width: none;
    }
  }
  @media(min-width: 650px) {
    #social-links {
      top: 2rem;
    }
  }

  p {
    margin: 1.5rem 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 0.75rem;
  }
  ul li {
    padding: 0;
    margin: 0;
  }
  ul li a {
    display: block;
  }
  @media(min-width: 650px) {
    ul {
      gap: 1rem;
    }
  }

  a {
    color: var(--text-color);
    transition: all 0.2s ease-in-out;
  }
  a:visited {
    color: var(--text-color);
  }
  a:hover,
  a:focus {
    color: var(--text-color-focus);
  }

  sl-icon.logo {
    width: auto;
    font-size: 2rem;
  }

`;
