import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { adminIndexViewStyles } from './admin-index-view-styles.js';

export class AdminIndexView extends ViewBase {
  static styles = [
    ...super.styles,
    adminIndexViewStyles
  ];

  static properties = {
    ...super.properties,
  };
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Q1 ADMIN PANEL');
  }

  get contentTemplate() {
    return html`
      <h1>Admin Index</h1>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
      </p>
    `;
  }
}

customElements.define('admin-index-view', AdminIndexView);
