import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { auth, signInWithCustomToken } from '../../firebaseConfig.js';

import { verifyEmailViewStyles } from './verify-email-view-styles.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { FormHostMixin } from '../../modules/form-host-mixin.js';
import { q1RegistrationFunctions } from '../../modules/q1-core-functions.js';

export class VerifyEmailView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    verifyEmailViewStyles,
  ];

  static properties = {
    ...super.properties,

    email: { type: String },
    code: { type: String },
    errorMessage: { type: String },
    authToken: { type: String },

    viewMode: { type: String }, // ready, processing, error, success
  };

  constructor() {
    super();

    this.email = this.urlParams.email || '';
    this.code = this.urlParams.code || '';
    this.errorMessage = null;
    this.authToken = undefined;

    this.viewMode = 'ready';
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Verify Email - Q1 Network');
  }

  firstUpdated() {
    this.updateComplete.then(() => {
      if (this.email?.length && this.code?.length) this.processCode();
    });
  }

  async processCode() {
    const { processVerificationCode } = q1RegistrationFunctions;

    // First verify the inputs and report to user if invalid
    const emailInput = this.renderRoot.querySelector('#email');
    const codeInput = this.renderRoot.querySelector('#code');
    if (!emailInput.reportValidity() || !codeInput.reportValidity()) return;

    this.viewMode = 'processing';

    const self = this;

    processVerificationCode({
      keyType: 'email',
      keyValue: this.email,
      verificationCode: this.code,
      generateAuthCode: !this.isSignedIn,
    }).then((result) => {
      const { message, resultCode, newlyConfirmed, authToken } = result.data || {};
      
      self.authToken = authToken;
      self.showToast(message, 'Verify Code Complete.', 'success');
      self.viewMode = 'success';

      if (newlyConfirmed) {
        this.appElement.queueToast(
          'Your membership is now confirmed.', 'Confirmation Complete!', 'success'
        );
      }
    }).catch((error) => {
      console.error('Error calling processVerificationCode:', error.message);

      self.errorMessage = error.message;
      self.viewMode = 'error';
    });
  }

  resendCode(event) {
    const { resetVerificationCode } = q1RegistrationFunctions;
    
    event.preventDefault();
    if (!confirm(`Reset and resend verification code for ${this.email}?`)) return;

    this.viewMode = 'processing';

    const self = this;
    
    resetVerificationCode({
      keyType: 'email',
      keyValue: this.email,
    }).then((result) => {
      const { message, resultCode } = result.data || {};

      self.viewMode = 'ready';
      self.showToast(message, 'Resend Verification Code Success.', 'success');
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      self.errorMessage = error.message;
      self.viewMode = 'error';
    });
  }

  submit(event) {
    event.preventDefault();

    this.processCode();
  }

  signIn(event) {
    event.preventDefault();

    signInWithCustomToken(auth, this.authToken).then((userCredential) => {
      console.log('Sign in successful, userCredential: ', userCredential);
      
      // NOTE: We want to trigger a full reload
      window.location.href = '/home';
    }).catch((error => {
      console.log(`Error signing in with token. Message: ${error.message}`);
      this.navigateTo('/signin');
    }));
  }

  get headerTemplate() { return null; }
  
  get footerTemplate() { return null; }

  get contentTemplate() {
    return html`

      <img src="../../assets/images/q1-logo-black-solid-flush.png" alt="Q1 Logo" class="logo"/>

      <h1>Q1 Network: Email Verification</h1>

      ${this.viewMode !== 'processing' ? null : html`

        <spinner-panel>
          <sl-spinner></sl-spinner>
          Processing your verification...
        </spinner-panel>

      `}
      
      ${this.viewMode !== 'success' ? null : html`

        <hr/>

        <div class="subtitle">
          <p>
            You have successfully verified ${this.email}! 
          </p>

          <p>
            ${this.isSignedIn ? html`
              Click the button below to continue to the app.
            ` : html`
              You can either continue on your other device or click the button below to sign in
              and continue here.
            `}
          </p>
        </div>

        <hr/>

        ${this.isSignedIn ? html`
          <sl-button href="/home">
            Continue to App
            <sl-icon slot="suffix" library="material" name="arrow_forward"></sl-icon>
          </sl-button>
        ` : html`
          <sl-button @click="${this.signIn}">
            Continue to App
            <sl-icon slot="suffix" library="material" name="arrow_forward"></sl-icon>
          </sl-button>
        `}

      `}

      ${!['ready', 'error'].includes(this.viewMode) ? null : html`
        
        <div class="subtitle">
          You should have received a code in your email.<br/>
          To verify your email address please enter the verification code below.
        </div>

        <hr/>
        
        <form id="verification-form" class="q1" @submit="${this.submit}">

          ${this.viewMode !== 'error' ? null : html`
            <sl-alert variant="danger" open>
              <sl-icon library="material" slot="icon" name="warning"></sl-icon>

              <strong>An error occurred processing your verification.</strong><br/>
            
              ${this.errorMessage}<br/>

              ${this.isSignedIn ? html`
                <a href="#" @click="${this.resendCode}">
                  Click here to send a new verification code
                </a>
              ` : html`
                <a href="/signin">Sign in to send a new verification code</a>
              `}
            </sl-alert>
          `}

          <sl-input 
            id="email" 
            name="email" 
            type="email"
            .value="${this.email}"
            @sl-input="${this.handleInput}"
            label="Email"
            required
          >
            <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
          </sl-input>
          
          <sl-input 
            id="code" 
            name="code" 
            .value="${this.code}"
            @sl-input="${this.handleInput}"
            label="Verification Code"
            pattern="\\d{6,6}"
            required
          >
            <sl-icon library="material" name="pin" slot="prefix"></sl-icon>
          </sl-input>

          <hr/>

          <sl-button @click="${this.submit}">Submit</sl-button>

        </form>

      `}

    `;
  }
}

customElements.define('verify-email-view', VerifyEmailView);
