import { css } from 'lit';

export const signInViewStyles = css`

  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--yellow-4) 100%);
  }

  sl-card {
    display: block;
    max-width: 30rem;
    --border-radius: 20px;
    --padding: 4rem;
    margin: 0 auto;
  }
  sl-card::part(base) {
    background: white;
  }

  .sign-in-logo img {
    width: 40px;
    margin-bottom: 1rem;
  }

  .sign-in-title {
    font-size: 1.5rem;
    line-height: 1.3em;
    font-weight: var(--sl-font-weight-semibold);
    color: #212529;
    margin: 0;
  }

  .sign-in-subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;
  }

  sl-input {
    width: 100%;
    margin-bottom: 1rem;
  }

  .button-group {
    display: flex;
    gap: 0.5rem;
  }

  sl-button::part(base) {
    width: 100%;
    font-weight: var(--sl-font-weight-semibold);
    padding: 0.75rem;
  }

  .sign-in-button {
    background-color: #f9c62a;
    color: #212529;
    border-radius: 9999px;
  }

  .join-button {
    border: 1px solid #d1d5db;
    color: #212529;
    background-color: white;
  }

  .social-sign-in {
    color: #3498db;
    font-size: 0.875rem;
    margin-top: 1rem;
  }

  .social-sign-in a {
    color: #3498db;
    text-decoration: none;
  }
`;
