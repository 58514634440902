import { css } from 'lit';

export const q1SpinnerPanelStyles = css`

  :host {
    display: contents;
    margin: 0;
  }

  outer-container {
    display: none;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(255, 255, 255, 0.8);
  }
  outer-container.spinner--open {
    display: flex;
  }
  outer-container.spinner--no-bg {
    background: none;
  }

  inner-container {
    display: flex;
    width: 20rem;
    margin: 0 auto;
    text-align: center;
    padding: 1.2rem;
    background: var(--sl-color-gray-100);
    border-radius: 7px;
    font-size: 1.2rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  outer-container.spinner--no-bg inner-container {
    background: white;
    box-shadow: var(--sl-shadow-large);
    border: 1px solid var(--gray-2);
  }

  sl-spinner {
    margin-right: 1rem;
    font-size: 1.7rem;
  }

`;
