import { LitElement, html, css } from 'lit';
import { collection, getDocs } from 'firebase/firestore';
import { viewStyles } from '../styles/view-styles.js';

import { AppHeader } from '../components/app-header/app-header.js';
import { AppFooter } from '../components/app-footer/app-footer.js';


export class ViewBase extends LitElement {

  static styles = [
    viewStyles,
  ];

  static properties = {
    isSignedIn: { type: Boolean },
    currentUser: { type: Object },
    currentMember: { type: Object },
    isAdmin: { type: Boolean },

    routeParams: { type: Object },
    urlParams: { type: Object },
    appElement: { type: Object },
  };

  constructor() {
    super();

    this.currentUser = null;
    this.currentMember = null;
    this.isSignedIn = false;
    this.isAdmin = false;

    this.routeParams = {};
    this.urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.appElement = null;
  }

  connectedCallback() {
    super.connectedCallback();

    this.urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.appElement = document.querySelector('q1-home');
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    if (this.selectedMember) {
      this.selectedMember = { ...this.selectedMember, [name]: value };
    }
  }

  /**
   * Use this to navigate to a particular path (`/projects/123`, `/home`, etc) from code.
   * Do not use this for direct user-click-driven navigation. For that just use `<a href="...">`.
   * This is an alternative to `window.location.href = '...'` which uses the app router and 
   * avoids a full page reload.
  */
  navigateTo(path) {
    const appElement = document.querySelector('q1-home');

    if (appElement) {
      appElement.navigateTo(path);
    }
  }

  /**
   * Adds a toast alert to the toast stack using `<sl-alert>`
   * 
   * @param {*} message - The message to display.
   * @param {*} title - Optional. If included, this is added as the first line, in bold.
   * @param {string} variant - `primary`, `success`, `neutral`, `warning`, `danger`
   * @param {*} duration - Time to display in milliseconds.
   */
  showToast(message, title = undefined, variant = undefined, duration = undefined) {
    const appElement = document.querySelector('q1-home');

    if (appElement) {
      appElement.showToast(message, title, variant, duration);
    }
  }

  /**
   * Use this method to set the title of the doc in the browser.
   * 
   * @param {string} title - The new value of the document title
   */
  setDocumentTitle(title = 'Q1 Network') {
    document.title = title;
  }

  get headerTemplate() {
    return html`
      <app-header
        .currentUser="${this.currentUser}"
        .currentMember="${this.currentMember}"
        .isSignedIn="${this.isSignedIn}"
        .isAdmin="${this.isAdmin}"
      ></app-header>
    `;
  }

  get footerTemplate() {
    return html`
      <app-footer></app-footer>
    `;
  }

  get contentTemplate() {
    return html``;
  }

  render() {
    return html`

      <view-header>
        ${this.headerTemplate}
      </view-header>

      <view-content>
        ${this.contentTemplate}
      </view-content>
      
      <view-footer>
        ${this.footerTemplate}
      </view-footer>

    `;
  }  
  
}