import { css } from 'lit';

export const appHeaderStyles = css`
  :host {
    display: flex;
    position: fixed;
    top: 0; right: 0; left: 0;
    z-index: 100;
    
    height: 50px;
    
    background: rgba(247, 251, 255, 0.50);
    backdrop-filter: blur(8px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 0 1rem;
    box-sizing: border-box;

    /* Default Colors */
    --text-color: var(--gray-6);
    --text-color-focus: var(--gray-9);
    --text-color-contrast: var(--gray-2);
  }
  :host([variant='white-text']) {
    background: rgba(0, 0, 0, 0.25);
    --text-color: white;
    --text-color-focus: var(--gray-3);
    --text-color-contrast: var(--gray-6);
  }
  @media(min-width: 650px) {
    :host {
      display: block;
    }
  }

  /*=== LEFT NAV ===*/
  
  .left-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    height: 100%;
  }
  @media(min-width: 650px) {
    .left-nav {
      position: absolute;
      top: 0; left: 1.5rem;
    }
  }

  .left-nav a {
    display: block;
    color: var(--text-color);
    transition: all 0.2s ease-in-out;
  }
  .left-nav a:visited {
    color: var(--text-color);
  }
  .left-nav a:hover,
  .left-nav a:focus {
    color: var(--text-color-focus);
  }
  .left-nav a sl-icon.logo {
    display: block;
    font-size: 25px;
  }

  /*=== CENTER NAV ===*/

  .center-nav {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    height: 100%;
    margin: 0 1.5rem;
    padding: 0;
    flex: 1 1 auto;

    box-sizing: content-box;
  }
  @media(min-width: 650px) {
    .center-nav {
      gap: 1.5rem;
      justify-content: center;

      margin: 0 auto;
      padding: 0 5rem;
      max-width: 800px;
    }
  }
  @media(min-width: 1000px) {
    .center-nav {
      justify-content: flex-start;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 1rem;
    line-height: 1em;
    text-decoration: none;
    
    color: var(--text-color);
    font-weight: 500;
    transition: all 0.2s ease-in-out;
  }
  .nav-link:visited {
    color: var(--text-color);
  }
  .nav-link:hover, .nav-link:focus {
    color: var(--text-color-focus);
  }
  .nav-link sl-icon {
    font-size: 1.3rem;
  }
  .nav-link span {
    display: none;
  }
  @media(min-width: 400px) {
    .nav-link span {
      display: block;
    }
  }
  @media(min-width: 650px) {
    .nav-link {
      gap: 0.4rem;
    }
  }

  /*=== RIGHT NAV ===*/

  .right-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    height: 100%;
  }
  @media(min-width: 650px) {
    .right-nav {
      position: absolute;
      top: 0; right: 1.5rem;
    }
  }

  sl-avatar {
    --size: 30px;
  }
  sl-avatar::part(icon) {
    color: var(--text-color-contrast);
  }
  sl-avatar::part(base) {
    background: var(--text-color);
    transition: all 0.2s ease-in-out;
  }
  sl-avatar:hover::part(base),
  sl-avatar:focus::part(base) {
    background: var(--text-color-focus);
    transition: all 0.2s ease-in-out;
  }

`;
