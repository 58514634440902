import { LitElement, html } from 'lit';
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';
import { auth, signOut, db, doc, getDoc } from './firebaseConfig.js';
import page from 'page';

import { q1Colors } from './styles/q1-colors.js';
import { shoelaceStyles } from './styles/shoelace-styles.js';
import { q1HomeStyles } from './q1-home-styles.js';

import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';

import { setupRoutes, appRender } from './routes.js';
import * as exports from './views/views.js';

class Q1Home extends LitElement {
  static styles = [
    q1Colors,
    shoelaceStyles,
    q1HomeStyles,
  ];

  static get properties() {
    return {
      currentUser: { type: Object },
      currentMember: { type: Object },
      isSignedIn: { type: Boolean },
      isAdmin: { type: Boolean },

      currentRoute: { type: String },
      routeParams: { type: Object },
      pageRouter: { type: Object },
      pageInstance: { type: Object },

      // IMPROVEME: This is a workaround. Search file for details.
      toastStack: { type: Object },
      isLoaded: { type: Boolean},
    };
  }

  constructor() {
    super();

    this.currentUser = null;
    this.currentMember = null;
    this.isSignedIn = false;
    this.isAdmin = false;

    this.currentRoute = '';
    this.routeParams = {};
    this.pageRouter = page;
    this.pageInstance = null;

    this.toastStack = null;
    this.isLoaded = false;
  }

  connectedCallback() {
    super.connectedCallback();
    
    this._initAuth();
    this._registerIconLibraries();
    this.addEventListener('sign-out', this.handleSignOut);
  }

  firstUpdated() {
    // Listen for click events so that we can potentially send them to pageRouter.
    // This is needed because the default listeners of Page.js don't detect certain events.
    this.renderRoot.addEventListener('click', this.handleClick.bind(this));
    
    // Other listeners
    this.renderRoot
      .addEventListener('refresh-current-member', this.refreshCurrentMember.bind(this));

    this.toastStack = this.renderRoot.querySelector('.sl-toast-stack');

    // If a toast has been queued with queueToast() then show it now
    this._showAndClearQueuedToast();
  }

  _initAuth() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.isSignedIn = true;
        this.currentUser = user;
        console.log('signed in', (new Date).toISOString());

        const idTokenResult = await user.getIdTokenResult();
        const memberId = idTokenResult.claims.memberId;

        //FIXME build out handling of edge cases
        const member = await getDoc(doc(db, 'members', memberId));
        this.currentMember = { id: memberId, ...member.data() };
        console.log('member loaded', (new Date).toISOString());

        this.isAdmin = ['admin', 'super'].includes(this.currentMember.adminLevel);

        this.pageInstance = setupRoutes(page, window);
      } else {
        console.log('signed out', (new Date).toISOString());
        // User is signed out.
        this.isSignedIn = false;
        this.currentUser = undefined;

        this.pageInstance = setupRoutes(page, window);
      }

      this.isLoaded = true;
    });
  }

  _registerIconLibraries() {
    registerIconLibrary('material', {
      resolver: name => {
        return `/assets/icons/material/outlined/${name}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });
    
    registerIconLibrary('q1', {
      resolver: name => {
        return `/assets/icons/q1/${name}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });

    registerIconLibrary('tabler', {
      resolver: name => {
        return `/assets/icons/tabler/${name}.svg`;
      },
    });
  }

  handleSignOut() {
    signOut(auth).then(() => {
      console.log('Sign out successful.');
      
      this.isSignedIn = false;
      this.currentUser = undefined;
      this.navigateTo('/');
      
      this.showToast(
        'You have been signed out of the Q1 network.', 'Sign out successful.', 'neutral'
      );

      this.isLoaded = true;
    }).catch((error) => {
      console.log('Sign out error:', error);
    });
  }

  async refreshCurrentMember() {
    const memberId = this.currentMember?.id;
    console.log('refreshCurrentMember memberId = ', memberId);
    if (!memberId) return;

    //FIXME build out handling of edge cases
    const member = await getDoc(doc(db, 'members', memberId));
    this.currentMember = { id: memberId, ...member.data() };
    console.log('member loaded', (new Date).toISOString());

    this.isAdmin = ['admin', 'super'].includes(this.currentMember.adminLevel);

    this.isLoaded = true;
  }

  _escapeHtml(html) {
    const div = document.createElement('div');
    div.textContent = html;
    return div.innerHTML;
  }

  /**
   * Use this to navigate to a particular path (`/projects/123`, `/home`, etc) from code.
   * Do not use this for direct user-click-driven navigation. For that just use `<a href="...">`.
   * This is an alternative to `window.location.href = '...'` which uses the app router and 
   * avoids a full page reload.
  */
  navigateTo(path) {
    if (this.pageRouter && path) {
      if (path.startsWith('/')) this.pageRouter(path);
      else window.location.href = path;
    }
  }

  /**
   * Adds a toast alert to the toast stack using `<sl-alert>`
   * 
   * @param {*} message - The message to display.
   * @param {*} title - Optional. If included, this is added as the first line, in bold.
   * @param {string} variant - `primary`, `success`, `neutral`, `warning`, `danger`
   * @param {*} duration - Time to display in milliseconds.
   */
  showToast(message, title = undefined, variant = 'primary', duration = 3000) {
    const iconMap = {
      primary: 'info',
      success: 'task_alt',
      neutral: 'settings',
      warning: 'warning',
      danger: 'report',
    };
    const icon = iconMap[variant] || 'info';

    const alert = Object.assign(document.createElement('sl-alert'), {
      variant,
      closable: true,
      duration: duration,
      innerHTML: `
        <sl-icon library="material" name="${icon}" slot="icon"></sl-icon>
        ${!title ? '' : `<strong>${title}</strong><br/>`}
        ${this._escapeHtml(message)}
      `
    });

    /*
      IMPROVEME: Workaround. Shoelace creates the toast stack in root document where it is 
      unstyled. We should find a better way to handle this or create a PR for shoelace.
      
      Refer to source code here...
      https://github.com/shoelace-style
      /shoelace/blob/next/src/components/alert/alert.component.ts#L195
    */
    
    alert.toast(); // This causes the alert to be reparented to toast stack in root document
    this.toastStack.appendChild(alert); // We reparent to our own toast stack
    
    return alert;
  }

  /**
   * Saves a toast message to display the next time the page loads.
   * Used to persist toast messages across page navs which trigger a reload.
   * NOTE: This implementation only supports one queued toast at a time.
   */
  queueToast(message, title, variant, duration) {
    const toastParams = { message, title, variant, duration };

    localStorage.setItem('queuedToast', JSON.stringify(toastParams));
  }

  _showAndClearQueuedToast() {
    const toastParams = JSON.parse(localStorage.getItem('queuedToast')) || {};

    if (toastParams?.message) {
      this.showToast(
        toastParams.message,
        toastParams.title,
        toastParams.variant,
        toastParams.duration
      );

      localStorage.removeItem('queuedToast');
    }
  }

  /**
   * This listens to all click events within the app and captures those triggered by anchor link
   * elements (unless the keyboard state indicates the desire to open in a new tab/window).
   * Captured events are prevented from triggering browser navigation and are instead directed
   * to `this.navigateTo()`.
   */
  handleClick(event) {
    if (event.metaKey || event.ctrlKey || event.shiftKey) return; // open in new tab/window
    if (event.defaultPrevented) return; // already handled elsewhere

    const target = event.target;
    const originalTarget = event.composedPath()[0];
    const anchor = originalTarget.closest('a');

    if (
      anchor && anchor.pathname
      && (anchor.hostname === window.location.hostname)
      && (anchor.target !== '_blank')
    ) {
      event.preventDefault();
      this.navigateTo(anchor.pathname + anchor.search);
    }
  }

  render() {
    if (!this.isLoaded) {
      return html``;
    }
    
    return html`
      ${appRender({
        currentRoute: this.currentRoute,
        routeParams: this.routeParams,
        currentUser: this.currentUser,
        currentMember: this.currentMember,
        isSignedIn: this.isSignedIn,
        isAdmin: this.isAdmin,
      })}
      <div class="sl-toast-stack"></div>
    `;
  }
}

customElements.define('q1-home', Q1Home);

export default Q1Home;
