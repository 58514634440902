import { html, css, LitElement } from 'lit';

import { appHeaderStyles } from './app-header-styles.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';

export class AppHeader extends LitElement {
  static styles = [ appHeaderStyles ];

  static properties = {
    variant: { type: String, reflect: true }, // default, white-text

    currentUser: { type: Object },
    currentMember: { type: Object },
    isSignedIn: { type: Boolean },
    isAdmin: { type: Boolean },
    
    hideCenterNav: { type: Boolean, attribute: 'hide-center-nav' },
    hideRightNav: { type: Boolean, attribute: 'hide-right-nav' },
    hideLeftNav: { type: Boolean, attribute: 'hide-left-nav' },

    appElement: { type: Object },
  };

  constructor() {
    super();

    this.variant = 'default';

    this.currentUser = null;
    this.currentMember = null;
    this.isSignedIn = false;
    this.isAdmin = false;

    this.hideCenterNav = false;
    this.hideRightNav = false;
    this.hideLeftNav = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.appElement = document.querySelector('q1-home');
  }

  handleMenuSelect(event) {
    event.preventDefault();
    const menuItemValue = event.detail?.item?.value;

    switch (menuItemValue) {
      case 'admin':
        this.appElement.navigateTo('/a');
        break;

      case 'settings':
        this.appElement.navigateTo('/settings');
        break;
      
      case 'sign-out':
        this.appElement.handleSignOut();
        break;
      
      default:
        console.log(`INVALID menu select item value: ${menuItemValue}`);
    }
  }

  handleSignOut() {
    this.dispatchEvent(new CustomEvent('sign-out', {
      detail: {},
      bubbles: true,
      composed: true,
    }));
  }

  render() {
    return html`
      ${this.hideLeftNav ? null : html`
        <div class="left-nav">
          <sl-tooltip content="Q1 Network Home">
            <a href="${this.isSignedIn ? '/home' : '/'}">
              <sl-icon
                library="q1"
                name="q1-logo-solid"
                label="Q1 Logo"
                class="logo"
              ></sl-icon>
            </a>
          </sl-tooltip>
        </div>
      `}

      ${this.hideCenterNav ? null : html`
        <div class="center-nav">
          <a href="/guilds" class="nav-link">
            <sl-icon library="material" name="hive"></sl-icon>
            <span>Guilds</span>
          </a>
          <a href="/projects" class="nav-link">
            <sl-icon library="material" name="business_center"></sl-icon>
            <span>Projects</span>
          </a>
        </div>
      `}

      ${this.hideRightNav ? null : html`
        <div class="right-nav">
          ${this.isSignedIn ? html`

            <sl-button
              size="small"
              pill
              href="/invite"
              variant="warning"
              outline
            >
              <sl-icon library="material" name="person_add" slot="prefix"></sl-icon>
              Invite
            </sl-button>

            <sl-dropdown distance="8">
              <sl-avatar
                slot="trigger"
                label="${this.currentMember.nameInternal}"
              ></sl-avatar>
              <sl-menu @sl-select=${this.handleMenuSelect}>
                <sl-menu-label>
                  ${this.currentMember.nameInternal} (@${this.currentMember.number})
                </sl-menu-label>
                ${this.isAdmin ? html`
                  <sl-menu-item value="admin">
                    <sl-icon library="material" name="shield_person" slot="prefix"></sl-icon>
                    Admin
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                ` : ''}
                <sl-menu-item value="settings">
                  <sl-icon library="material" name="settings" slot="prefix"></sl-icon>
                  Settings
                </sl-menu-item>
                <sl-divider></sl-divider>
                <sl-menu-item value="sign-out">
                  <sl-icon library="material" name="logout" slot="prefix"></sl-icon>
                  Sign Out
                </sl-menu-item>
              </sl-menu>
            </sl-dropdown>

          `:html`

            <sl-button size="small" pill href="/signin">Sign In</sl-button>
            <sl-button size="small" pill variant="primary" href="/join">Join</sl-button>

          `}
        </div>
      `}
    `;
  }
}

customElements.define('app-header', AppHeader);