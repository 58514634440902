import { css } from 'lit';

export const Q1TelInputStyles = css`
  :host, :root {
    display: block;
    box-sizing: border-box;
    line-height: 1.1rem;

    --iti-path-flags-1x: url('/assets/images/flags.webp');
    --iti-path-flags-2x: url('/assets/images/flags@2x.webp');
    --iti-path-globe-1x: url('/assets/images/globe.webp');
    --iti-path-globe-2x: url('/assets/images/globe@2x.webp');

    --iti-border-color: var(--gray-5);
    --iti-dialcode-color: var(--gray-4);
    --iti-dropdown-bg: white;
    --iti-arrow-color: var(--gray-9);
    --iti-hover-color: var(--yellow-1);
  }

  .iti__globe {
    opacity: 0.3;
    /* IMPROVEME: This is workaround to match sl icon color. Replace image instead. */
  }

  .iti {
    display: block;
  }

  label {
    display: inline-block;
    font-size: var(--sl-input-label-font-size-medium);
    color: var(--sl-input-label-color);
    margin-bottom: var(--sl-spacing-3x-small);
    box-sizing: inherit;
  }
  :host([required]) label::after {
    content: var(--sl-input-required-content);
    /* margin-inline-start: var(--sl-input-required-content-offset); */
    margin-left: 0.15rem;
    color: var(--sl-input-required-content-color);
  }
  
  input {
    display: block;
    box-sizing: inherit;
    width: 100%;

    border: solid var(--sl-input-border-width) var(--sl-input-border-color);
    border-radius: var(--sl-input-border-radius-medium);
    font-size: var(--sl-input-font-size-medium);
    height: var(--sl-input-height-medium);
    background-color: var(--sl-input-background-color);
    transition:
      var(--sl-transition-fast) color,
      var(--sl-transition-fast) border,
      var(--sl-transition-fast) box-shadow,
      var(--sl-transition-fast) background-color;
  }

  input:focus {
    background-color: var(--sl-input-background-color-focus);
    border-color: var(--sl-input-border-color-focus);
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-input-focus-ring-color);
  }

  #help-text {
    display: block;
    color: var(--sl-input-help-text-color);
    margin-top: var(--sl-spacing-3x-small);
    font-size: var(--sl-input-help-text-font-size-medium);
    box-sizing: inherit;
  }

`;
