import { css } from 'lit';

export const projectIndexViewStyles = css`

  :host {
    background: var(--q1-home-background, var(--gray-1));
    }

  .header {
    display: flex;
    align-items: center;
    gap: 1rem;  /* Space between h1 and button */
    margin-bottom: 2rem;
  }

  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem 0;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }

  h1 {
    margin: 0;
  }

  sl-button::part(base) {
    background: white;
    padding: 0.5rem;
    font-size: 0.875rem;
  }
`;
