import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { projectIndexViewStyles } from './project-index-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '../../components/q1-project-chip/q1-project-chip.js';

export class ProjectIndexView extends ViewBase {
  static styles = [
    ...super.styles,
    projectIndexViewStyles
  ];

  static properties = {
    ...super.properties,
    projects: { type: Array },
    loading: { type: Boolean }
  };

  constructor() {
    super();
    this.projects = [];
    this.loading = true;
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.isSignedIn) {
      this.initLists();
    }
  }

  async initLists() {
    const getProjects = httpsCallable(functions, 'list-getProjects');

    try {
      const projectsResult = await getProjects();
      this.projects = projectsResult.data.projects || [];
    } catch (error) {
      console.log('Error getting projects:', error.message);
    } finally {
      this.loading = false;
    }
  }

  renderProjects() {
    if (this.loading) {
      return html`
        <div class="loading-container">
          <sl-spinner></sl-spinner>
        </div>
      `;
    }
  
    return html`
      <div class="projects-grid">
        ${this.projects.map(project => html`
          <q1-project-chip
            .project=${project}
            href="/projects/${project.id}"
          ></q1-project-chip>
        `)}
      </div>
    `;
  }

  get contentTemplate() {
    if (this.isSignedIn) {
      return html`
        <div class="header">
          <h1>Projects</h1>
          <sl-button size="small" @click=${this.initLists}>
            <sl-icon slot="prefix" library="material" name="refresh"></sl-icon>
            Refresh
          </sl-button>
        </div>

        ${this.renderProjects()}
      `;
    }

    return html`
      <h1>Browse All Projects</h1>
      
      <sl-alert variant="warning" open>
        <sl-icon library="material" name="lock" slot="icon"></sl-icon>
        
        <strong>Restricted to Q1 Network Members During Private Beta.</strong><br/><br/>
        
        The Q1 Network is currently in private beta.
        During this time this feature is only accessible to existing members.
        Please check back in the coming weeks or follow the
        <a href="https://www.linkedin.com/company/quorum1" target="_blank">Quorum1 LinkedIn page</a>
        to get notified when the public beta is released.
      </sl-alert>
    `;
  }
}

customElements.define('project-index-view', ProjectIndexView);