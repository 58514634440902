import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { projectViewStyles } from './project-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';

export class ProjectView extends ViewBase {
  static styles = [
    ...super.styles,
    projectViewStyles
  ];

  static properties = {
    ...super.properties,
    project: { type: Object },
    loading: { type: Boolean },
    error: { type: String }
  };

  constructor() {
    super();
    this.project = null;
    this.loading = true;
    this.error = '';
  }

  connectedCallback() {
    super.connectedCallback();
    
    if (this.isSignedIn && this.routeParams.key) {
      this.fetchProject();
    }
  }

  async fetchProject() {
    const getProject = httpsCallable(functions, 'list-getProject');

    try {
      const result = await getProject({ projectId: this.routeParams.key });
      this.project = result.data.project;
      this.setDocumentTitle(`${this.project.name} - Q1 Network Projects`);
    } catch (error) {
      console.error('Error fetching project:', error);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }

  get contentTemplate() {
    if (!this.isSignedIn) {
      return html`
        <sl-alert variant="primary" open>
          Please sign in to view project details.
        </sl-alert>
      `;
    }

    if (this.loading) {
      return html`
        <div class="loading-container">
          <sl-spinner></sl-spinner>
        </div>
      `;
    }

    if (this.error) {
      return html`
        <sl-alert variant="danger" open>
          ${this.error}
        </sl-alert>
      `;
    }

    return html`
      <div class="project-container">
        <h1>${this.project.name}</h1>
        <div class="project-details">
          <p><strong>Point Number:</strong> ${this.project.pointNumber}</p>
          <p class="description">${this.project.description}</p>
          
          ${this.project.betaOrganizerNumbers?.length ? html`
            <p>
              <strong>Beta Organizers:</strong> 
              ${this.project.betaOrganizerNumbers.join(', ')}
            </p>
          ` : ''}
        </div>
      </div>
    `;
  }
}

customElements.define('project-view', ProjectView);