import { html, LitElement } from 'lit';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateDoc } from 'firebase/firestore';

import { q1UploadInputStyles } from './q1-upload-input-styles';

export class q1UploadInput extends LitElement {
  static styles = [
    q1UploadInputStyles,
  ];

  static properties = {
    name: { type: String, reflect: true },
    projectRecord: { type: Object },
  };

  constructor() {
    super();
    this.name = '';
    this.projectRecord = null;
  }

  handleFileUpload(event) {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (!file) {
      console.error('No file selected.');
      return;
    }

    this.uploadFile(this.name, file)
    .then((downloadURL) => {
      this.projectRecord.data[this.name] = downloadURL;
      this.requestUpdate();
    })
    .catch((error) => {
      console.error(`Error handling file upload for ${this.name}:`, error);
    });
  }

  uploadFile(fieldName, file) {
    const storage = getStorage();
    const projectRefPath = this.projectRecord?.ref?.path;
    const timestamp = Date.now();
    const ext = file.name.split('.').pop();
    const filePath = `inbound/${projectRefPath}/${timestamp}.${ext}`;
    const fileRef = storageRef(storage, filePath);

    return uploadBytes(fileRef, file)
      .then(() => getDownloadURL(fileRef))
      .catch((error) => {
        console.error(`Error uploading file for ${fieldName}:`, error);
        throw error;
      });
  }

  render() {
    return html`
      <label for="${this.name}">${this.name}</label>
      <input
        id="${this.name}"
        type="file"
        @change="${this.handleFileUpload}"
      />
    `;
  }
}

customElements.define('q1-upload-input', q1UploadInput);