import { css } from 'lit';

export const viewStyles = css`
  :host {
    display: block;
    background: var(--gray-1);

    font-family: 'system-ui', sans-serif;
    line-height: 1.5em;
  }

  view-content {
    display: block;
    margin: 0 auto;
    padding: 8rem 2rem 4rem 2rem;
    
    box-sizing: content-box;
    max-width: 800px;
    min-height: 60vh;
  }

  view-content hr {
    border: 0;
    border-top: 1px solid var(--gray-4);
    margin: 2.5rem 0;
    display: block;
  }

  view-content p.subtitle {
    margin: 2.5rem 0;
    display: block;
    font-size: 1.1rem;
  }

  view-content sl-alert {
    margin: 2.5rem 0;
  }
  @media(min-width: 1000px) {
    view-content sl-alert {
      margin: 3.5rem 0;
    }
  }


  /* FORM CUSTOM ELEMENTS */

  field-spacer {
    display: block;
  }
  field-pair {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 0;
    margin: 1.5rem 0;
  }
  field-pair sl-input,
  field-pair q1-tel-input,
  field-pair field-spacer {
    flex: 1 1 15rem;
    min-width: min(15rem, 100%);
  }


  /* FORM STYLES */

  form.q1 h2 {
    margin: 2.5rem 0;
  }

  form.q1 sl-input {
    line-height: 1.1rem;
  }
  form.q1 sl-input:not(:last-child),
  form.q1 q1-tel-input:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  form.q1 sl-alert {
    margin: 2rem 0;
  }

  /* SHOELACE OVERRIDES */

  sl-button[variant=primary]::part(base) {
    color: var(--gray-9);
  }
  
  sl-button.secondary::part(base) {
    background: var(--teal-6);
  }

`;
