import { css } from 'lit';

export const joinViewStyles = css`

  :host {
    background: var(--gray-9);
    color: white;
  }
  
  view-content {
    margin: 0;
    padding: 0 0 4rem 0;
    max-width: none;
  }

  section {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }
  section-content {
    display: block;
    margin: 0 auto;
    padding: 0 2rem;
    
    box-sizing: border-box;
    max-width: 800px;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.3em;
    margin: 0;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.3em;
    margin: 0;
  }

  div.subtitle {
    margin: 2.5rem 0;
    font-size: 1.1rem;
  }

  section#hero {
    position: relative;
    padding: 12rem 2rem 14rem 2rem;
    
    background: white;
    color: var(--gray-9);
    overflow: hidden;

    border-radius: 0 0 5rem 5rem;
  }
  section#hero::before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;

    background-image: url('/assets/images/q1-member-mosaic.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: grayscale(1) opacity(0.5);
  }
  
  section#hero::after {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 2;

    --gradient-stops: 12;
    background: linear-gradient(
      135deg,
      var(--profile-red),
      var(--profile-tangerine),
      var(--profile-orange),
      var(--profile-yellow),
      var(--profile-lime),
      var(--profile-green),
      var(--profile-teal),
      var(--profile-sky),
      var(--profile-blue),
      var(--profile-purple),
      var(--profile-raspberry),
      var(--profile-magenta)
    );
    background-size:
      calc(33% * var(--gradient-stops))
      calc(33% * var(--gradient-stops));

    -webkit-animation: GradientAnimation 30s ease infinite;
    -moz-animation: GradientAnimation 30s ease infinite;
    animation: GradientAnimation 30s ease infinite;

    filter: opacity(0.8);
  }
  section#hero section-content {
    padding: 2.5rem;
    background: white;
    border-radius: 12px;
    box-shadow: var(--sl-shadow-x-large);
    border: 1px solid var(--gray-3);

    position: relative;
    z-index: 3;
  }
  section#hero q1-section-divider {
    color: var(--teal-9);
    z-index: 4;
  }

  @-webkit-keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }

  section#explainer {
    background: linear-gradient(180deg, var(--teal-9) 0%, var(--teal-9) 50%, var(--teal-8) 100%);
    color: white;
    padding: 9rem 0 4rem 0;
    margin-top: -5rem;
    z-index: 1;
  }
  section#explainer h2 {
    margin-top: 0;
  }

  #explainer ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 0;
    margin: 2.5rem 0;
  }
  #explainer ul li {
    display: flex;
    gap: 1rem;
    align-items: center;

    flex: 1 0 80%;
  }
  #explainer ul li icon-frame {
    flex: 0 0 auto;
  }
  #explainer ul li span {
    display: block;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.3em;
    font-weight: 100;
  }
  #explainer ul li span strong {
    font-size: 1.3rem;
    line-height: 1.7em;
  }
  @media(min-width: 650px) {
    #explainer ul li {
      max-width: calc(50% - 1rem);
      flex-basis: 15rem;
    }
  }
  @media(min-width: 1000px) {
    #explainer ul {
      margin: 3.5rem 0;
    }
  }

  icon-frame {
    display: flex;
    position: relative;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--teal-5);
    border-radius: 100%;
  }
  icon-frame::before {
    content: '';
    position: absolute;
    background: var(--teal-3);
    border-radius: 100%;
    opacity: 0.3;
    
    width: 4rem; height: 4rem;
    top: 0; left: 0;
    z-index: 2;
  }
  icon-frame::after {
    content: '';
    position: absolute;
    background: var(--teal-3);
    border-radius: 100%;
    opacity: 0.1;
    
    width: 4rem; height: 4rem;
    bottom: -4px; right: -5px;
    z-index: 1;
  }
  icon-frame sl-icon {
    font-size: 2rem;
    display: block;
    color: var(--teal-5);
    z-index: 3;
  }

  section#process {
    padding: 4rem 0;
    background: linear-gradient(180deg, var(--teal-9) 0%, var(--gray-8) 6%, var(--gray-8) 100%);
    border-top: 1px solid var(--teal-7);
    border-bottom: 1px solid var(--gray-7);
  }
  section#process::before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-image: url('/assets/textures/dots-white.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: opacity(0.02);
    z-index: 1;
  }

  #process content-inner {
    display: block;
    border-left: 4px solid var(--teal-6);
    padding-left: 1rem;
    margin-left: 1rem;
  }
  #process content-inner > h3 {
    margin: 2rem 0 1rem 0;
  }
  #process content-inner > h3 > span {
    display: inline-flex;
    line-height: 1em;
    height: 2rem;
    width: 2rem;
    align-items: center;
    justify-content: center;
    
    margin-right: 0.4rem;
    margin-left: -2.18rem;

    border-radius: 100%;
    background: var(--teal-6);
    border: 1px solid var(--teal-4);
  }
  #process content-inner > p {
    padding-left: 0.7rem;
    margin: 0;
  }

  cta-wrapper {
    display: flex;
    align-items: flex-start
  }
  cta-arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    width: 7rem;
    height: 7rem;
    margin: 0 0.5rem 0 1rem;
    border-left: 4px solid var(--teal-6);
    border-bottom: 4px solid var(--teal-6);
    border-bottom-left-radius: 12px;
  }
  cta-arrow-head {
    display: block;
    position: relative;
    bottom: -12px;
    right: -4px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    border-left: 15px solid var(--teal-6);
    z-index: 4;
  }
  cta-panel {
    display: block;
    position: relative;
    flex: 1 1 auto;

    padding: 2.5rem;
    border-radius: 12px;
    border: 1px solid var(--teal-5);
    margin: 3rem 0 0 0;
  }
  cta-panel sl-button {
    z-index: 3;
  }
  cta-panel h3,
  cta-panel p {
    display: block;
    font-size: 1.3rem;
    line-height: 1.3em;
    position: relative;
    z-index: 3;
    margin: 0 0 2rem 0;
  }
  cta-panel h3 {
    font-weight: 600;
  }
  cta-panel p {
    font-weight: 200;
  }
  cta-panel::before {
    content: '';
    position: absolute;
    background: var(--teal-3);
    border-radius: 12px;
    opacity: 0.3;
    
    width: 100%; height: 100%;
    top: 0; left: 0;
    z-index: 2;
  }
  cta-panel::after {
    content: '';
    position: absolute;
    background: var(--teal-3);
    border-radius: 12px;
    opacity: 0.1;
    
    width: 100%; height: 100%;
    bottom: -4px; right: -5px;
    z-index: 1;
  }

  sl-button[variant='warning']::part(base) {
    border: 1px solid var(--teal-4);
  }

`;
