import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { settingsViewStyles } from './settings-view-styles.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

export class SettingsView extends ViewBase {
  static styles = [
    ...super.styles,
    settingsViewStyles,
  ];

  static properties = {
    ...super.properties,
  };

  connectedCallback() {
    super.connectedCallback();
    this.setDocumentTitle('Your Settings - Q1 Network');
  }

  get contentTemplate() {
    if (!this.currentMember) {
      return html`
        <sl-spinner></sl-spinner>
      `;
    }

    return html`
      <h1>Your Account Settings</h1>

      <p class="subtitle">
        View your profile information and account details in the Q1 Network.
      </p>

      <hr/>

      <form id="settings-form" class="q1">
        <h2>Basic Information</h2>

        <field-pair>
          <sl-input 
            id="nameInternal"
            value="${this.currentMember?.nameInternal || ''}"
            label="Display Name"
            help-text="Your name visible to other Q1 members"
            readonly
          >
            <sl-icon library="material" name="person" slot="prefix"></sl-icon>
          </sl-input>

          <sl-input 
            id="nameExternal"
            value="${this.currentMember?.nameExternal || ''}"
            label="Profile Name"
            help-text="Your publicly visible name"
            readonly
          >
            <sl-icon library="material" name="public" slot="prefix"></sl-icon>
          </sl-input>
        </field-pair>

        <field-pair>
          <sl-input 
            id="number"
            value="${this.currentMember?.number || ''}"
            label="Member Number"
            readonly
          >
            <sl-icon library="material" name="tag" slot="prefix"></sl-icon>
          </sl-input>

          <sl-input 
            id="level"
            value="${this.currentMember?.level || ''}"
            label="Member Level"
            readonly
          >
            <sl-icon library="material" name="star" slot="prefix"></sl-icon>
          </sl-input>
        </field-pair>

        <hr/>

        <h2>Contact Details</h2>

        <field-pair>
          <sl-input 
            id="emailPrimary"
            value="${this.currentMember?.emailPrimary || ''}"
            label="Primary Email"
            type="email"
            readonly
          >
            <sl-icon library="material" name="email" slot="prefix"></sl-icon>
          </sl-input>

          <sl-input 
            id="phoneMobile"
            value="${this.currentMember?.phoneMobile || ''}"
            label="Mobile Phone"
            readonly
          >
            <sl-icon library="material" name="phone" slot="prefix"></sl-icon>
          </sl-input>
        </field-pair>

        ${this.currentMember?.linkedinProfileUrl ? html`
          <field-pair>
            <sl-input 
              id="linkedinProfileUrl"
              value="${this.currentMember.linkedinProfileUrl}"
              label="LinkedIn Profile"
              type="url"
              readonly
            >
              <sl-icon library="material" name="link" slot="prefix"></sl-icon>
              <a 
                href="${this.currentMember.linkedinProfileUrl}" 
                target="_blank" 
                rel="noopener noreferrer" 
                slot="suffix"
              >
                <sl-icon library="material" name="open_in_new"></sl-icon>
              </a>
            </sl-input>
            <field-spacer></field-spacer>
          </field-pair>
        ` : ''}

        ${this.currentMember?.emailsAll?.length ? html`
          <field-pair>
            <sl-input 
              id="emailsAll"
              value="${this.currentMember.emailsAll.join(', ')}"
              label="Additional Emails"
              help-text="All emails associated with your account"
              readonly
            >
              <sl-icon library="material" name="alternate_email" slot="prefix"></sl-icon>
            </sl-input>
            <field-spacer></field-spacer>
          </field-pair>
        ` : ''}

        <hr/>

        <h2>Account Status</h2>

        <field-pair>
          <sl-input 
            id="status"
            value="${this.currentMember?.status || ''}"
            label="Account Status"
            readonly
          >
            <sl-icon library="material" name="verified_user" slot="prefix"></sl-icon>
          </sl-input>

          <sl-input 
            id="onboardStatus"
            value="${this.currentMember?.onboardStatus || ''}"
            label="Onboarding Progress"
            readonly
          >
            <sl-icon library="material" name="checklist" slot="prefix"></sl-icon>
          </sl-input>
        </field-pair>

        ${this.currentMember?.agreementMemberTimestamp ? html`
          <field-pair>
            <sl-input 
              id="agreementMemberTimestamp"
              value="${this.currentMember.agreementMemberTimestamp}"
              label="Member Agreement Signed"
              readonly
            >
              <sl-icon library="material" name="fact_check" slot="prefix"></sl-icon>
            </sl-input>
            <field-spacer></field-spacer>
          </field-pair>
        ` : ''}
      </form>
    `;
  }
}

customElements.define('settings-view', SettingsView);