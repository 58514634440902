/**
 * Add this to a component to add the properties and methods needed to turn it into
 * a W3C-compliant form element. Example below.
 * 
 * ```html
 * export class q1 extends FormInputMixin(ViewBase) { ... }
 * ```
 * 
 * Notes on implementation:
 * https://dev.to/stuffbreaker/custom-forms-with-web-components-and-elementinternals-4jaj
 */
export const FormInputMixin = (SuperClass) => class extends SuperClass {

  static formAssociated = true;
  static shadowRootOptions = { ...SuperClass.shadowRootOptions, delegatesFocus: true };

  static properties = {
    label: { type: String, reflect: true },
    name: { type: String, reflect: true },
    value: { type: String, reflect: true },
    required: { type: Boolean, reflect: true },
    valid: { type: Boolean, reflect: true },
    helpText: { type: String, reflect: true, attribute: 'help-text' },
    
    _internals: { type: Object, reflect: true },
  };
  
  constructor() {
    super();

    this.name = '';
    this.value = '';
    this.required = false;
    this.valid = true;

    this._internals = this.attachInternals();
  }

  checkValidity() {
    return this._internals.checkValidity();
  }
  
  reportValidity() {
    return this._internals.reportValidity();
  }
  
  get validity() {
    return this._internals.validity;
  }
  
  get validationMessage() {
    return this._internals.validationMessage;
  }

  /**
   * Overwrite this one (and call super) to implement setting of this.value and this.valid.
   */
  handleInput() {
    this.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
  }

  handleChange() {
    this.dispatchEvent(new Event('change', { bubbles: true, composed: true }));
  }

}
