import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { homePublicViewStyles } from './home-public-view-styles.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';

import * as d3 from 'd3'; 
import 'd3-dsv';

import Globe from 'globe.gl';

export class HomePublicView extends ViewBase {
  static styles = [
    ...super.styles,
    homePublicViewStyles,
  ];

  static properties = {
    ...super.properties,

    globe: { type: Object },
    controls: { type: Object },
  };

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('The Q1 Network - Venture Collective for the Future of Work');
  }

  firstUpdated() {
    const q1GlobeElement = this.renderRoot.querySelector('q1-globe');

    this.globe = new Globe(q1GlobeElement, { animateIn: false })
      .globeImageUrl('/assets/images/earth.png')
      .backgroundImageUrl('/assets/images/night-sky-inverse.png')
      .width(document.body.clientWidth)
      .height(document.body.clientHeight * 0.8)
      .backgroundColor('#FFFFFF')
      .atmosphereColor('#61F8E6')
      .hexBinPointWeight('pop')
      .hexAltitude(d => d.sumWeight * 6e-8)
      .hexBinResolution(4)
      .hexTopColor(() => '#E4B21A')
      .hexSideColor(() => '#F9C62A')
      .ringsData([])
      .ringColor(() => '#B7901C')
      .ringMaxRadius(10)
      .ringPropagationSpeed(3)
      .hexBinMerge(true)
      .enablePointerInteraction(true); // performance improvement

    fetch('assets/data/world_population.csv').then(res => res.text())
      .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
      .then(data => this.globe.hexBinPointsData(data));

    this.controls = this.globe.controls();
    this.controls.autoRotate = true;
    this.controls.enableZoom = false;

    this.globe.onGlobeClick(({ lat, lng }) => {
      this.globe.ringsData([...this.globe.ringsData(), { lat, lng }]);
    });

    this._resizeObserver = new ResizeObserver(entries => {
      const lastEntry = entries[entries.length - 1];
      if (lastEntry) {
        const { width } = lastEntry.contentRect;
        this.globe.width(width);
        this.globe.height(document.body.clientHeight * 0.6);
      }
    });
    this._resizeObserver.observe(this);
  }

  _handleResize() {

  }

  get footerTemplate() {
    return html`
      <app-footer variant="white-text"></app-footer>
    `;
  }

  get contentTemplate() {
    return html`

      <section id="hero">
        <section-content>
          <div class="pretitle">The Q1 Network hosts</div>
          <h1>the future of work</h1>
        </section-content>
      </section>

      <section id="subhero">
        <section-content>
          <h2>We are a global collective. We exist to help you...</h2>

          <ul>
            <li>
              <sl-icon library="q1" name="3d-interlink"></sl-icon>
              <span>
                <strong>Learn & Grow</strong><br/>
                Follow inspiring projects aligned with your interests & learning goals
              </span>
            </li>
            <li>
              <sl-icon library="q1" name="3d-polygon"></sl-icon>
              <span>
                <strong>Work & Earn</strong><br/>
                Contribute your work for fun, for money, or for future returns
              </span>
            </li>
            <li>
              <sl-icon library="q1" name="3d-toroid"></sl-icon>
              <span>
                <strong>Build & Operate</strong><br/>
                Source help for your organization or startup something brand new
              </span>
            </li>
          </ul>
          
          <button-bar>
            <sl-button
              variant="primary"
              size="large"
              href="/join"
              pill
            >
              Join the Quorum
            </sl-button>
          </button-bar>
        </section-content>
      </section>

      <section id="social">
        <section-content>
          
          <h2>The Q1 network is launching soon!</h2>

          <hr/>

          <div class="subtitle">
            <strong>We're currently in private beta.</strong>
            Wider access is rolling out from Nov 2024 through Feb 2025.<br/><br/>
            
            The Q1 Network is backed by a diverse global community of thousands of Quorum1 members,
            supporters, investors, clients, and partners. Here are just a few.
          </div>

          <hr/>

          <ul>
            <li><img src="assets/logos/slack.png"></li>
            <li><img src="assets/logos/republic.png"></li>
            <li><img src="assets/logos/learning-economy-foundation.png"></li>
            <li><img src="assets/logos/dict.png"></li>
            <li><img src="assets/logos/aircall.png"></li>
            <li><img src="assets/logos/aurora-solar.png"></li>
            <li><img src="assets/logos/building-beyond.png"></li>
            <li><img src="assets/logos/covalence.png"></li>
            <li><img src="assets/logos/dumele.png"></li>
            <li><img src="assets/logos/scoby-collective.png"></li>
            <li><img src="assets/logos/salesforce.png"></li>
            <li><img src="assets/logos/redwire.png"></li>
            <li><img src="assets/logos/human-potential-capital.png"></li>
            <li><img src="assets/logos/gaianet.png"></li>
          </ul>
        </section-content>
      </section>
      
      <section id="explainer">
        <section-content>
          <h2>Show up in all the ways that fit you.</h2>

          <div class="subtitle">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </div>
          <div class="subtitle">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
          </div>
        </section-content>
      </section>

      <q1-globe></q1-globe>
    `;
  }
}

customElements.define('home-public-view', HomePublicView);
