import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { homeOnboardViewStyles } from './home-onboard-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';
import { updateMemberById } from '../../modules/firestore-helpers.js';

/** Maps from current onboardStatus to what the corresponding currentStepNumber is. */
const currentStepNumberMap = {
  'none': 1,
  'step-1-complete': 2,
  'step-2-complete': 3,
  'step-3-complete': 4,
  'complete': 4,
};

/** Maps from current step number to what onboardStatus should be after completing. */
const onboardStatusMap = {
  1: 'step-1-complete',
  2: 'step-2-complete',
  3: 'step-3-complete',
  4: 'complete',
};

export class HomeOnboardView extends ViewBase {
  static styles = [
    ...super.styles,
    homeOnboardViewStyles,
  ];

  static properties = {
    ...super.properties,
    
    currentStepNumber: { type: Number }, // Max step from onboardStatus = 1, 2, 3, 4
    stepNumber: { type: Number }, // 1, 2, 3, 4
    
    hasPriorStep: { type: Boolean }, // true if there is a prior step
    hasNextStep: { type: Boolean }, // true if there is a next step
    atCurrentStep: { type: Boolean }, // true if stepNumber is the max step
    
    isProcessing: { type: Boolean },
  };

  constructor() {
    super();

    this.currentStepNumber = 1;
    this.stepNumber = 1;
    this.hasPriorStep = false;
    this.hasNextStep = true;
    this.atCurrentStep = true;
  }
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Member Onboarding - Q1 Network');

    this.currentStepNumber = currentStepNumberMap[this.currentMember?.onboardStatus];
    this._setStep(this.currentStepNumber);
  }

  /**
   * Sets the stepNumber and related properties.
   * - Does NOT touch database.
   * - DOES correct stepNumber if out of range.
   * - Does NOT throw errors.
   * */
  _setStep(stepNumber) {
    stepNumber = Math.max(1, Math.min(4, Math.round(stepNumber))); // fix if out of range [1 to 4]

    this.stepNumber = stepNumber;
    this.hasPriorStep = stepNumber > 1;
    this.hasNextStep = stepNumber < 4;
    this.atCurrentStep = stepNumber === this.currentStepNumber;
  }

  /** Goes back to the prior step. Does not touch database. */
  priorStep() {
    this._setStep(this.stepNumber - 1);
  }

  /**
   * Proceeds to the next step and touches the database if needed.
   */
  nextStep() {
    if (!this.hasNextStep) return;

    if (!this.atCurrentStep) {
      // No DB update needed, we can just move the UI forward
      this._setStep(this.stepNumber + 1);
      return;
    }

    // Else we need to update the DB
    this.isProcessing = true;

    // Update the DB onboardStatus
    updateMemberById(this.currentMember.id, {
      onboardStatus: onboardStatusMap[this.stepNumber],
    }).then(() => {
      this.currentStepNumber = this.stepNumber + 1;
      this._setStep(this.stepNumber + 1);
      this.isProcessing = false;
    }).catch((error) => {
      console.log('ERROR updating member:', error.message);
      this.showToast(error.message, 'Uh oh! We hit an error.', 'danger');
      this.isProcessing = false;
    });
  }

  completeOnboarding() {
    if (this.hasNextStep) return;

    // We need to update the DB
    this.isProcessing = true;

    const memberStatus = this.currentMember.status;

    // Update the DB onboardStatus
    updateMemberById(this.currentMember.id, {
      onboardStatus: onboardStatusMap[this.stepNumber],
      status: (memberStatus === 'confirmed') ? 'onboarded' : memberStatus,
    }).then(() => {
      this.appElement.queueToast(
        'Congratulations and welcome to the Quorum.', 'Onboarding Complete!', 'success'
      );
      
      // Do a full page reload
      window.location.href = '/home';
    }).catch((error) => {
      console.log('ERROR updating member:', error.message);
      this.showToast(error.message, 'Uh oh! We hit an error.', 'danger');
      this.isProcessing = false;
    });
  }

  get contentTemplate() {
    return html`

      <content-container>
        <h1>
          <span class="prefix">Member Onboarding:</span>
          Step ${this.stepNumber} of 4
        </h1>
  
        <p class="subtitle">
          You can exit this process at any point and come back to it later.
        </p>
  
        <hr/>
  
        ${this.stepNumber == 1 ? this.renderStep1() : null}
        ${this.stepNumber == 2 ? this.renderStep2() : null}
        ${this.stepNumber == 3 ? this.renderStep3() : null}
        ${this.stepNumber == 4 ? this.renderStep4() : null}

        <q1-spinner-panel label="Saving..." no-bg ?open=${this.isProcessing}></q1-spinner-panel>
      </content-container>

      <hr/>

      <button-bar>
        <sl-button
          id="prior-step"
          @click="${this.priorStep}"
          ?disabled="${!this.hasPriorStep || this.isProcessing}"
        >
          <sl-icon library="material" name="arrow_back" slot="prefix"></sl-icon>
          Prior Step
        </sl-button>
        
        ${this.hasNextStep ? html`
          <sl-button
            id="next-step"
            @click="${this.nextStep}"
          >
            Next Step
            <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
          </sl-button>
        ` : html`
          <sl-button
            id="complete-onboarding"
            @click="${this.completeOnboarding || this.isProcessing}"
          >
            Complete Onboarding
            <sl-icon library="material" name="check_circle" slot="suffix"></sl-icon>
          </sl-button>
        `}
      </button-bar>

    `;
  }

  renderStep1() {
    return html`
      <h2>FIXME - Stub of Step 1</h2>
      
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id 
        est laborum. His cu probo blandit. Vivendum intellegat et qui, ei denique consequuntur vix. 
        Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque 
        quo cu, veri persius vituperata ei nec. Sonet tibique sea et. Scripta periculis ei eam, te 
        pro movet reformidans. 
      </p>
    `;
  }

  renderStep2() {
    return html`
      <h2>FIXME - Stub of Step 2</h2>
      
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id 
        est laborum. His cu probo blandit. Vivendum intellegat et qui, ei denique consequuntur vix. 
        Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque 
        quo cu, veri persius vituperata ei nec. Sonet tibique sea et. Scripta periculis ei eam, te 
        pro movet reformidans. 
      </p>
    `;
  }

  renderStep3() {
    return html`
      <h2>FIXME - Stub of Step 3</h2>
      
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id 
        est laborum. His cu probo blandit. Vivendum intellegat et qui, ei denique consequuntur vix. 
        Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque 
        quo cu, veri persius vituperata ei nec. Sonet tibique sea et. Scripta periculis ei eam, te 
        pro movet reformidans. 
      </p>
    `;
  }

  renderStep4() {
    return html`
      <h2>FIXME - Stub of Step 4</h2>
      
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id 
        est laborum. His cu probo blandit. Vivendum intellegat et qui, ei denique consequuntur vix. 
        Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque 
        quo cu, veri persius vituperata ei nec. Sonet tibique sea et. Scripta periculis ei eam, te 
        pro movet reformidans. 
      </p>
    `;
  }

}

customElements.define('home-onboard-view', HomeOnboardView);
