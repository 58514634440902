import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { auth, signInWithCustomToken } from '../../firebaseConfig.js';

import { registerViewStyles } from './register-view-styles.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { FormHostMixin } from '../../modules/form-host-mixin.js';
import { q1RegistrationFunctions } from '../../modules/q1-core-functions.js'
import { Q1TelInput } from '../../components/q1-tel-input/q1-tel-input.js';

export class RegisterView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    registerViewStyles,
  ];

  static properties = {
    ...super.properties,

    regCode: { type: String },

    nameInternal: { type: String },
    nameExternal: { type: String },
    emailPrimary: { type: String },
    emailsAdditional: { type: Array },
    emailsAdditionalText: { type: String },
    linkedinProfileUrl: { type: String },
    phoneMobile: { type: String },
    password: { type: String },
    nameLegal: { type: String },

    // FIXME/TEMP-ALPHA - Remove all references to verification step post alpha.
    viewMode: { type: String }, // unverified, ready, processing, form-error, verify-processing
    errorMessage: { type: String },
  };

  constructor() {
    super();

    this.regCode = this.urlParams.code || '';

    this.nameInternal = this.urlParams.name || '';
    this.nameExternal = this.urlParams.name || '';
    this.emailPrimary = this.urlParams.email || '';
    this.emailsAdditional = [];
    this.emailsAdditionalText = '';
    this.linkedinProfileUrl = '';
    this.phoneMobile = this.urlParams.phone || '';
    this.password = '';
    this.nameLegal = this.urlParams.name || '';

    this.viewMode = 'unverified';
  }
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('New Member Registration - Q1 Network');
  }

  get handleInputPostProcess() {
    return {
      emailsAdditionalText: this.handleEmailsAdditionalTextChange,
    };
  }

  handleEmailsAdditionalTextChange() {
    this.emailsAdditional = [
      ...new Set(
        (this.emailsAdditionalText || '')
          .split(',')
          .map(e => e.trim().toLowerCase())
          .filter(e => e.includes('@'))
      )
    ];
  }

  handleVerificationFormSubmit(event) {
    const { verifyRegCode } = q1RegistrationFunctions;
    const verificationForm = this.renderRoot.querySelector('#verification-form');
    const regCodeInput = this.renderRoot.querySelector('#regCode');
    
    event.preventDefault();
    
    if (!verificationForm.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      return;
    }

    this.viewMode = 'verify-processing';
    this.regCode = this.regCode.trim().toLowerCase();

    const verificationFormData = { regCode: this.regCode };
    console.log('VERIFICATION SUBMIT. verificationFormData: ', verificationFormData);
    
    verifyRegCode(verificationFormData).then((result) => {
      console.log('verifyRegCode result: ', result);

      this.showToast('Registration code verified!', null, 'success');
      this.viewMode = 'ready';
    }).catch((error) => {
      console.error('Error calling function:', error.message);

      this.showToast('Registration code invalid', null, 'danger');
      this.viewMode = 'unverified';
      this.updateComplete.then(() => {
        regCodeInput.focus();
      });
    });
  }
  
  handleRegistrationFormSubmit(event) {
    const { newMember } = q1RegistrationFunctions;
    const registrationForm = this.renderRoot.querySelector('#registration-form');
    
    event.preventDefault();
    
    if (!registrationForm.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      return;
    }

    this.viewMode = 'processing';
    this.errorMessage = '';

    const registrationFormData = {
      ...this._serializeForm(registrationForm),
      emailsAdditional: this.emailsAdditional,
    };
    console.log('REGISTRATION SUBMIT. registrationFormData: ', registrationFormData);
    
    newMember(registrationFormData).then((result) => {
      console.log('newMember result: ', result);

      const authToken = result?.data?.authToken;

      signInWithCustomToken(auth, authToken).then((userCredential) => {
        console.log('Sign in successful, userCredential: ', userCredential);
        
        document.querySelector('q1-home').queueToast(
          'The next step is to finish confirming your account',
          'Member registration received!', 'success'
        );
        
        // NOTE: We want to trigger a full reload
        window.location.href = '/home';
      }).catch((error => {
        this.errorMessage = `Error signing in with token. Message: ${error.message}`;
        console.log(this.errorMessage);
        this.viewMode = 'form-error';
      }));
    }).catch((error) => {
      console.error('Error calling function:', error.message);

      this.errorMessage = error.message;
      this.viewMode = 'form-error';
    });
  }

  get headerTemplate() {
    return html`
      <app-header
        hide-center-nav
        hide-right-nav
      ></app-header>
    `;
  }

  get contentTemplate() {
    if (['unverified', 'verify-processing'].includes(this.viewMode)) return html`
      
      <h1>Enter Registration Code</h1>

      <hr/>

      <sl-alert variant="warning" open>
        <sl-icon library="material" name="info" slot="icon"></sl-icon>
        
        <strong>The Q1 Network is currently in private beta.</strong><br/>
        
        During this time, joining requires a beta registration code.
        Please check back in the coming weeks or follow the
        <a href="https://www.linkedin.com/company/quorum1" target="_blank">Quorum1 LinkedIn page</a>
        to get notified when the public beta is released.
      </sl-alert>

      <form id="verification-form" @submit=${this.handleVerificationFormSubmit}>
        <sl-input 
          id="regCode" 
          name="regCode" 
          .value="${this.regCode}"
          @sl-input="${this.handleInput}"
          label="Registration Code"
          size="large"
          required
          autofocus
        ></sl-input>

        <hr/>

        <sl-button
          variant="warning"
          size="large"
          type="submit"
          pill
          ?disabled="${this.viewMode === 'verify-processing'}"
        >
          Save &amp; Continue
          <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
        </sl-button>
      </form>

    `;

    return html`
      <h1>Welcome to the private beta! Let's get you all setup.</h1>

      <p class="subtitle">
        Fill out the form below to register in the Q1 Network and become a member of the Quorum1 
        collective.
      </p>

      ${this.viewMode !== 'processing' ? null : html`
        <sl-alert variant="primary" open>
          <sl-spinner slot=icon style="font-size: 1.5rem;"></sl-spinner>
          <strong>Processing your registration...</strong><br/>
        </sl-alert>
      `}

      <hr/>

      <form id="registration-form" class="q1" @submit=${this.handleRegistrationFormSubmit}>

        <input type="hidden" name="regCode" value=${this.regCode} />
        
        ${this.viewMode !== 'form-error' ? null : html`
          <sl-alert variant="danger" open>
            <sl-icon library="material" slot="icon" name="exclamation-triangle"></sl-icon>
            <strong>An error occurred in your member registration.</strong><br/>
            
            ${this.errorMessage}
          </sl-alert>
        `}
        
        <h2>Your Account</h2>
        
        <field-pair>
          <sl-input 
            id="nameInternal" 
            name="nameInternal" 
            .value="${this.nameInternal}"
            @sl-input="${this.handleInput}"
            label="Display Name (Internal)" 
            help-text="What other Quorum1 members see"
            required
          ></sl-input>

          <sl-input 
            id="nameExternal" 
            name="nameExternal" 
            .value="${this.nameExternal}"
            @sl-input="${this.handleInput}"
            label="Profile Name (External)" 
          >
            <span slot="help-text">
              What is shown publicly to non-members.
              Set this field to a blank value to be externally anonymous.
            </span>
          </sl-input>
        </field-pair>
        
        <field-pair>
          <sl-input 
            id="emailPrimary"
            name="emailPrimary"
            .value="${this.emailPrimary}"
            @sl-input="${this.handleInput}"
            type="email"
            label="Primary Email" 
            required
          ></sl-input>
          
          <!-- No name attribute. This is processed before passing in the form data. -->
          <sl-input 
            id="emailsAdditionalText"
            .value="${this.emailsAdditionalText}"
            @sl-input="${this.handleInput}"
            label="Additional Emails" 
            help-text="Link your other emails to help collaborators find you. Separate with commas."
          ></sl-input>
        </field-pair>
        
        <field-pair>
          <sl-input 
            id="linkedinProfileUrl"
            name="linkedinProfileUrl"
            .value="${this.linkedinProfileUrl}"
            @sl-input="${this.handleInput}"
            type="url"
            label="LinkedIn Profile URL" 
            pattern="https?:\/\/.*linkedin\.com\/in\/.*"
            required
          >
            <span slot="help-text">
              Required to verify your professional identity.
              Reach out to team@quorum.one if you don't have a LI profile.
            </span>
          </sl-input>

          <q1-tel-input
            id="phoneMobile"
            name="phoneMobile"
            .value="${this.phoneMobile}"
            @input="${this.handleInput}"
            label="Mobile Phone"
            help-text="Used for important comms according to your preferences"
            required
          ></q1-tel-input>
        </field-pair>

        <field-pair>
          <sl-input 
            id="password"
            name="password"
            .value="${this.password}"
            @sl-input="${this.handleInput}"
            label="Password" 
            type="password"
            required
            minlength="6"
            password-toggle
          ></sl-input>
          <field-spacer></field-spacer>
        </field-pair>

        <hr/>

        <h2>Who Referred You?</h2>

        <p class="subtitle">
          If a member, project or guild helped to refer you to Quorum1, 
          you can give them credit below.
        </p>

        <p>
          FIXME - Add fields for: referrerMember, referrerProject, referrerGuild
        </p>
        
        <hr/>

        <h2>Legal Agreement</h2>
        
        <field-pair>
          <sl-input 
            id="nameLegal"
            name="nameLegal"
            .value="${this.nameLegal}"
            @sl-input="${this.handleInput}"
            label="Legal Name" 
            help-text="Used for important comms according to your preferences"
            required
          >
            <sl-icon library="material" name="signature" slot="prefix"></sl-icon>
            <sl-icon library="material" name="lock" slot="suffix"></sl-icon>
          </sl-input>
          <field-spacer></field-spacer>
        </field-pair>

        <!-- FIXME - Update links below once gov repo release 4 is ready -->
        <p>
          By joining Quorum1 you are agreeing to our
          <a
            href"https://github.com/quorum1/governance/blob/main/docs/community-agreement.md"
            target="_blank"
          >Member Agreement</a>
          and our
          <a
            href"https://github.com/quorum1/governance"
            target="_blank"
          >Terms of Service</a>.
        </p>

        <!-- FIXME - Add recaptcha -->
        
        <hr/>

        <sl-button
          variant="primary"
          size="large"
          type="submit"
          pill
          ?disabled="${this.viewMode === 'processing'}"
        >
          Save &amp; Continue
          <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
        </sl-button>
      </form>

    `;
  }
}

customElements.define('register-view', RegisterView);
