import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { homeSignedInViewStyles } from './home-signed-in-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';

const firstGreeting = 'Welcome to the Q1 network!';
const allGreetings = [
  "Welcome back to the network!",
  "Howdy! It's great to see you again.",
  "Glad to have you with us today!",
  "Welcome! Let's get things moving.",
  "Welcome back! Let's make it count.",
  "Good to see you back in action!",
  "Hello! Let's dive in.",
  "Welcome! Let's create something amazing.",
  "Greetings! Your network awaits.",
  "It's wonderful to have you back.",
  "Hello again! Time for some work joy.",
  "It's great to have you here!",
  "Welcome back! Let's make progress.",
  "Good to see you! Let's get started.",
  "Welcome! Let's make great things happen.",
  "Hi! Let's connect and collaborate.",
];

export class HomeSignedInView extends ViewBase {
  static styles = [
    ...super.styles,
    homeSignedInViewStyles,
  ];

  static properties = {
    ...super.properties,
    
    greeting: { type: String },
  };

  constructor() {
    super();

    this.greeting = firstGreeting;
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Q1 Network Home');

    this.setupDayAndGreeting();
  }

  setupDayAndGreeting() {
    const today = this._dayAsNumber();
    let firstDay = JSON.parse(localStorage.getItem('firstDay'));

    // First check for the first day
    if (!firstDay) {
      firstDay = today;
      localStorage.setItem('firstDay', firstDay);
    }
    if (today === firstDay) return; // keep firstGreeting value

    // Then check for same day
    let lastDay = JSON.parse(localStorage.getItem('lastDay')) || today;
    let lastGreeting = localStorage.getItem('lastGreeting');
    if ((lastDay === today) && lastGreeting?.length) {
      this.greeting = lastGreeting;
      return;
    }

    // Else today is a new day! Time for a new greeting.
    lastDay = today;
    let remainingGreetings;
    try {
      remainingGreetings = JSON.parse(localStorage.getItem('remainingGreetings') || '[]');
    } catch (error) {
      console.log(`Error selecting random greeting: ${error.message}`);
    }
    if (!remainingGreetings?.length) remainingGreetings = [...allGreetings];

    const randomIndex = Math.floor(Math.random() * remainingGreetings.length);
    lastGreeting = remainingGreetings.splice(randomIndex, 1)[0];
    this.greeting = lastGreeting;

    // Now update local storage
    localStorage.setItem('lastDay', lastDay);
    localStorage.setItem('lastGreeting', lastGreeting);
    localStorage.setItem('remainingGreetings', JSON.stringify(remainingGreetings));
  }

  _dayAsNumber() {
    return Math.floor(Date.now() / (1000 * 60 * 60 * 24));
  }

  get contentTemplate() {
    return html`
      <h1>${this.greeting}</h1>

      <hr/>

      <p class="subtitle">
        The Q1 network is currently in private beta. The public beta will start soon.<br/>
        <br/>
        In the meantime, things will be a little rough around the edges. If you have any questions
        please reach out to <a href="mailto:team@quorum.one">team@quorum.one</a>.
      </p>
    `;
  }
}

customElements.define('home-signed-in-view', HomeSignedInView);
