import { html } from 'lit';
import { ViewBase } from '../view-base.js';

import { homeConfirmViewStyles } from './home-confirm-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { Q1VerificationInfo } from '../../components/q1-verification-info/q1-verification-info.js';

export class HomeConfirmView extends ViewBase {
  static styles = [
    ...super.styles,
    homeConfirmViewStyles,
  ];
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Confirm Your Membership - Q1 Network');
  }

  get contentTemplate() {
    return html`
      <h1>One more step...</h1>
      <p>
        Verification codes &amp; links have been texted and emailed to you.
        To confirm your member registration, you can either follow the verification links or
        manually enter the verification codes below.
      </p>
      
      <hr/>

      <q1-verification-info
        .verificationInfo=${this.currentMember?.verificationInfo}
        .emailPrimary=${this.currentMember?.emailPrimary}
      ></q1-verification-info>

      <hr/>

      <h2>How your contact info makes the Q1 Network more useful to you</h2>

      <p>
        <strong>Emails:</strong>
        By linking and verifying all of the emails you use for work or professional 
        development, you're able to:
      </p>
      
      <ol>
        <li>Direct updates from your guilds &amp; projects to the most relevant address</li>
        <li>Gain access to guilds &amp; projects based on your prior relationships</li>
        <li>Make it easier for your prior collaborators to find you</li>
      </ol>

      <p>
        <strong>Mobile Phone:</strong>
        Text messages are used sparingly, but they are a key channel for delivering 
        short updates about the projects you flag as being most important to you.
      </p>

      <p>
        Quorum1 is a member-owned collective working to bring professionals into mutually
        beneficial collaboration. We don't spam or send out marketing messages. 
      </p>
    `;
  }
}

customElements.define('home-confirm-view', HomeConfirmView);
