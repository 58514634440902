import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { profileEditViewStyles } from './profile-edit-view-styles.js';

export class ProfileEditView extends ViewBase {
  static styles = [
    ...super.styles,
    profileEditViewStyles
  ];

  static properties = {
    ...super.properties,
  };

  get contentTemplate() {
    const guildKey = this.params;
    return html`
      <h1>Guild Profile Edit View</h1>
      <p>Guild Key: ${guildKey}</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
      </p>
      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    `;
  }
}

customElements.define('profile-edit-view', ProfileEditView);
