import { css } from 'lit';

export const verifyEmailViewStyles = css`
  
  .logo {
    height: 3rem;
  }

  spinner-panel {
    display: block;
    font-size: 1.5rem;
    margin: 4rem 0;
  }
  spinner-panel sl-spinner {
    margin-right: 1rem;
  }

`;
