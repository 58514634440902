import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { guildJoinViewStyles } from './guild-join-view-styles.js';

export class GuildJoinView extends ViewBase {
  static styles = [
    ...super.styles,
    guildJoinViewStyles
  ];

  static properties = {
    ...super.properties,
  };
  
  // FIXME >> Add call to setDocumentTitle()

  get contentTemplate() {
    const guildKey = this.params;
    return html`
      <h1>Guild Join View</h1>
      <p>Guild Key: ${guildKey}</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
      </p>
    `;
  }
}

customElements.define('guild-join-view', GuildJoinView);
