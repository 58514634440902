import { css } from 'lit';

export const homeOnboardViewStyles = css`
  
  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 85%, var(--teal-2) 100%);
  }

  content-container {
    display: block;
    position: relative; /* needed to contain spinner panel */
  }

  button-bar {
    display: flex;
    justify-content: space-between;
  }

`;
