import { html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { q1SpinnerPanelStyles } from './q1-spinner-panel-styles.js';

export class Q1SpinnerPanel extends LitElement {
  static styles = [ q1SpinnerPanelStyles ];

  static properties = {    
    label: { type: String, reflect: true },
    open: { type: Boolean, reflect: true },
    noBackground: { type: Boolean, reflect: true, attribute: 'no-bg' },
  };

  constructor() {
    super();

    this.label = 'Loading...';
    this.open = false;
  }

  render() {
    return html`
      <outer-container class=${classMap({
        'spinner--open': this.open,
        'spinner--no-bg': this.noBackground,
      })}>
        <inner-container>
          <sl-spinner></sl-spinner>
          <span>${this.label}</span>
        </inner-container>
      </outer-container>
    `;
  }

}

customElements.define('q1-spinner-panel', Q1SpinnerPanel);
