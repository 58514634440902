import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { adminUpdatesViewStyles } from './admin-updates-view-styles.js';

export class AdminUpdatesView extends ViewBase {
  static styles = [
    ...super.styles,
    adminUpdatesViewStyles
  ];

  static properties = {
    ...super.properties,
  };
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Q1 ADMIN: Updates');
  }

  get contentTemplate() {
    return html`
      <h1>Admin Updates</h1>
      <p>

        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
      </p>
    `;
  }
}

customElements.define('admin-updates-view', AdminUpdatesView);
