import { css } from 'lit';

export const q1HomeStyles = css`

  :host {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;

    background: var(--q1-home-background, var(--gray-1));
  }

`;
