import { html, css, LitElement, unsafeCSS } from 'lit';
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
import intlTelInputStyles from 'intl-tel-input/styles';

import { Q1TelInputStyles } from './q1-tel-input-styles.js';

import { FormInputMixin } from '../../modules/form-input-mixin.js';

// import '@shoelace-style/shoelace/dist/components/button/button.js';

export class Q1TelInput extends FormInputMixin(LitElement) {
  static styles = [
    css`${unsafeCSS(intlTelInputStyles)}`,
    Q1TelInputStyles,
  ];

  static properties = {
    ...super.properties, // adds: label, name, value, required, valid, helpText, _internals

    input: { type: Object },
    iti: { type: Object },
  };

  constructor() {
    super();
    
    this.input = null;
    this.iti = null;
  }

  connectedCallback() {
    super.connectedCallback();

    this.updateFormValue();
  }

  firstUpdated() {
    this.updateComplete.then(() => {
      this.input = this.renderRoot.querySelector('#input');
  
      this.iti = intlTelInput(this.input, {
        countryOrder: ['us'],
        dropdownContainer: this.renderRoot,
        separateDialCode: true,
      });

      this.updateValidity(); // can't run until this.input exists
    });
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  updateValidity() {
    if (this.required && !this.value.length) {
      this._internals.setValidity(
        { valueMissing: true },
        'Please fill out this required field',
        this.input,
      );
    } else if (!this.valid) {
      this._internals.setValidity(
        { typeMismatch: true },
        'Please enter a valid phone number',
        this.input,
      );
    } else {
      this._internals.setValidity({}, '', this.input);
    }
  }
  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  updateFormValue() {
    this._internals.setFormValue(this.value);
  }
  
  handleInput() {
    this.value = this.iti.getNumber();
    this.valid = this.iti.isValidNumber();
    this.updateFormValue();
    this.updateValidity();

    super.handleInput(); // dispatches the event
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  formDisabledCallback(disabled) {
    this.input.disabled = disabled;
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  formResetCallback() {
    this.value = '';
  }

  // FIXME >> Build out a basic error validation mode.. user-facing error message
  render() {
    return html`
      ${!this.label?.length ? null : html`
        <label for="input">${this.label}</label>
      `}
      <input
        id="input"
        type="tel"
        .value="${this.value}"
        @input="${this.handleInput}"
        @change="${this.handleChange}"
      />
      ${!this.helpText?.length ? null : html`
        <div id="help-text">${this.helpText}</div>
      `}
    `;
  }
}

customElements.define('q1-tel-input', Q1TelInput);
