import { css } from 'lit';

export const adminProjectsViewStyles = [
  css`

    h1 {
      font-size: 1.5rem;
      font-weight: var(--sl-font-weight-semibold);
      margin-bottom: 1rem;
      color: var(--sl-color-neutral-900);
    }

    content-container {
      display: block;
      position: relative;
      min-height: 20rem;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 1rem 0;
      background-color: var(--sl-color-neutral-100);
      border: 1px solid var(--sl-color-neutral-300);
    }

    thead {
      background-color: var(--sl-color-primary-50);
    }

    th {
      text-align: left;
      padding: var(--sl-spacing-medium);
      font-size: var(--sl-font-size-small);
      font-weight: var(--sl-font-weight-semibold);
      color: var(--sl-color-neutral-900);
      border-bottom: 1px solid var(--sl-color-neutral-300);
    }

    tbody tr:nth-child(even) {
      background-color: var(--sl-color-neutral-50);
    }

    td {
      padding: var(--sl-spacing-medium);
      font-size: var(--sl-font-size-small);
      color: var(--sl-color-neutral-700);
      border-bottom: 1px solid var(--sl-color-neutral-200);
    }

    td.actions {
      display: flex;
      gap: var(--sl-spacing-small);
    }

    sl-button {
      font-size: var(--sl-font-size-small);
    }

    sl-dialog {
      --sl-panel-background-color: var(--sl-color-neutral-100);
      --sl-panel-border-radius: var(--sl-border-radius-medium);
      --sl-panel-box-shadow: var(--sl-shadow-large);
      --sl-header-font-size: var(--sl-font-size-large);
      --sl-header-font-weight: var(--sl-font-weight-semibold);
      --width: 80vw;
    }

    sl-input {
      margin-bottom: var(--sl-spacing-medium);
    }

    sl-select {
      margin-bottom: var(--sl-spacing-medium);
    }

    .actions {
      margin-top: var(--sl-spacing-large);
      display: flex;
      gap: var(--sl-spacing-medium);
    }

    sl-toast {
      --sl-font-sans: var(--sl-font-sans, Arial, sans-serif);
      --sl-color-primary: var(--sl-color-primary);
    }
  `,
];
