import { css } from 'lit';

export const homeConfirmViewStyles = css`
  
  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--yellow-2) 100%);
  }

  q1-verification-info {
    margin: 2.5rem 0;
  }

`;
