import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { projectJoinViewStyles } from './project-join-view-styles.js';

export class ProjectJoinView extends ViewBase {
  static styles = [
    ...super.styles,
    projectJoinViewStyles
  ];

  static properties = {
    ...super.properties,
  };
  
  // FIXME >> Add call to setDocumentTitle()

  get contentTemplate() {
    return html`
        <h1>Project Join View</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
      </p>
    `;
  }
}

customElements.define('project-join-view', ProjectJoinView);
