import { html, LitElement } from 'lit';
import { q1ProjectChipStyles } from './q1-project-chip-styles.js';

export class Q1ProjectChip extends LitElement {
  static styles = [ q1ProjectChipStyles ];

  static properties = {
    project: { type: Object },
    href: { type: String }
  };

  constructor() {
    super();
    this.project = {};
    this.href = '';
  }

  render() {
    return html`
      <a class="project-chip" href="${this.href}">
        <div class="project-icon">
          <img src="${this.project.icon}" alt="${this.project.name} icon">
        </div>
        <span class="project-name">${this.project.name}</span>
      </a>
    `;
  }
}

customElements.define('q1-project-chip', Q1ProjectChip);