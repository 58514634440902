import { css } from 'lit';

export const q1GuildChipStyles = css`
  :host {
    display: block;
    margin: 2rem 0;
  }

  .guild-chip {
    padding: 1rem 1.5rem;
    background: white;
    border: 1px solid var(--yellow-3);
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
    color: inherit;
  }

  .guild-chip:hover {
    background: var(--yellow-1);
    border-color: var(--yellow-4);
  }

  .guild-name {
    font-size: 1.2rem;
    line-height: 1em;
    font-weight: 500;
  }
`;