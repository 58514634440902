import { css } from 'lit';

export const q1UploadInputStyles = css`
  :host, :root {
    display: block;

  input {
    display: block;
    width: 100%;
    
    border: solid var(--sl-input-border-width) var(--sl-input-border-color);
    border-radius: var(--sl-input-border-radius-medium);
    font-size: var(--sl-input-font-size-medium);
    height: var(--sl-input-height-medium);
    background-color: var(--sl-input-background-color);
    transition:
      var(--sl-transition-fast) color,
      var(--sl-transition-fast) border,
      var(--sl-transition-fast) box-shadow,
      var(--sl-transition-fast) background-color;
  }

  input:focus {
    background-color: var(--sl-input-background-color-focus);
    border-color: var(--sl-input-border-color-focus);
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-input-focus-ring-color);
  }

`;
