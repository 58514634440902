import { initializeApp } from 'firebase/app';
import {
  getAuth, signOut, signInWithCustomToken, signInWithEmailAndPassword, connectAuthEmulator,
} from 'firebase/auth';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import {
  getFirestore, connectFirestoreEmulator,
  doc, updateDoc, getDoc, getDocs, deleteDoc, 
  collection,
  onSnapshot,
  query, limit, orderBy,
} from 'firebase/firestore';
import { connectStorageEmulator, getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';


const firebaseConfig = {
  apiKey: 'AIzaSyCv0wKBZs4GUy4DxU9eTI05qelTrGiH_nI',
  authDomain: 'q1-home-prod-74dd4.firebaseapp.com',
  projectId: 'q1-home-prod-74dd4',
  storageBucket: 'q1-home-prod-74dd4.appspot.com',
  messagingSenderId: '116054675714',
  appId: '1:116054675714:web:0a017f332ca6f13970b21b',
  measurementId: 'G-764QYJQ95H'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);


if ((location.hostname === 'localhost') || (location.hostname === '127.0.0.1')) {
  console.log('firebaseConfig: Dev environment detected.');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

// Initialize Firebase Analytics (only if supported, as it's browser-specific)
let analytics;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics(app);
    console.log('Firebase Analytics initialized');
  } else {
    console.log('Firebase Analytics is not supported in this environment.');
  }
});

export {
  app,
  auth,
  analytics,
  db,
  signOut,
  functions,
  httpsCallable,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
  onSnapshot,
  storage,
  getStorage,
  storageRef,
  uploadBytes,
  getDownloadURL,
  query, limit, orderBy,
};
