import { css } from 'lit';

export const q1ProjectChipStyles = css`
  :host {
    display: inline-block;
    margin: 0.5rem;
  }

  .project-chip {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;
    background: var(--gray-1);
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease;
  }

  .project-chip:hover {
    background: white;
  }

  .project-icon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .project-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .project-name {
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: 500;
    color: var(--gray-9);
  }
`;