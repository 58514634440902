import { functions, httpsCallable } from '../firebaseConfig.js';

const q1RegistrationFunctions = {
  newMember: httpsCallable(functions, 'registration-newMember'),
  resetVerificationCode: httpsCallable(functions, 'registration-resetVerificationCode'),
  processVerificationCode: httpsCallable(functions, 'registration-processVerificationCode'),
  removeEmail: httpsCallable(functions, 'registration-removeEmail'),
  addEmail: httpsCallable(functions, 'registration-addEmail'),
  changePhone: httpsCallable(functions, 'registration-changePhone'),
  verifyRegCode: httpsCallable(functions, 'registration-verifyRegCode'),
};

const q1ListFunctions = {
  getProjects: httpsCallable(functions, 'list-getProjects'),
};

export {
  q1ListFunctions,
  q1RegistrationFunctions,
}
