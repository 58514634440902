import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { signInViewStyles } from './sign-in-view-styles.js';
import { auth, signInWithEmailAndPassword } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/card/card.js';
import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';

export class SignInView extends ViewBase {
  static styles = [
    ...super.styles,
    signInViewStyles,
  ];

  static properties = {
    ...super.properties,
    
    email: { type: String },
    
    viewState: { type: String },
  };

  constructor() {
    super();

    this.email = this.urlParams.email || '';
    this.viewState = 'ready';
  }
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Sign In - Q1 Network');
  }

  firstUpdated() {
    // We need to wait to register form listeners until after shoelace inputs are defined.
    // Normally that requires a listener for sl element definition, but firstUpdated() should 
    // always fire after that.
    this.shadowRoot.querySelector('#sign-in-form')
      .addEventListener('submit', e => this.onSignInFormSubmit(e));
  }

  /** Runs after shoelace validation, handles the actual execution. */
  async onSignInFormSubmit(e) {
    e.preventDefault();
    this.viewState = 'processing';
    this.errorMessage = '';

    const signInForm = this.renderRoot.querySelector('#sign-in-form');
    const { email, password } = serialize(signInForm);
    
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      console.log('Sign in successful, userCredential: ', userCredential);
      
      // NOTE: We want to trigger a full reload so it requeries the member record
      window.location.href = '/home';
    }).catch((error => {
      console.log(`Error signing in. Message: ${error.message}`);
      this.errorMessage = error.message;
      this.viewState = 'form-error';
    }));
  }

  get contentTemplate() {
    return html`
      ${this.viewState !== 'form-error' ? null : html`
        <sl-alert variant="danger" open>
          <sl-icon library="material" slot="icon" name="exclamation-triangle"></sl-icon>
          <strong>An error occurred signing you in.</strong><br/>
          
          ${this.errorMessage}
        </sl-alert>
      `}
      
      <sl-card class="card-basic">
        <div class="sign-in-logo">
          <img src="../assets/images/q1-logo-black-solid-flush.png" alt="Q1 Logo" />
        </div>
        <h2 class="sign-in-title">Welcome to<br/>the Q1 Network</h2>
        <p class="sign-in-subtitle">Please sign in below.</p>

        <form id="sign-in-form">
          
          <!-- Email Input -->
          <sl-input
            id="email"
            name="email"
            type="email"
            label="Email"
            .value="${this.email}"
            ?disabled="${this.viewState === 'processing'}"
            required
            clearable
          >
            <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
          </sl-input>
  
          <!-- Password Input -->
          <sl-input
            id="password"
            name="password"
            type="password"
            label="Password"
            ?disabled="${this.viewState === 'processing'}"
            required
            clearable
          >
            <sl-icon library="material" name="key" slot="prefix"></sl-icon>
          </sl-input>
  
          <!-- Button Group -->
          <div class="button-group">
            <sl-button
              id="sign-in-button"
              type="submit"
              ?disabled="${this.viewState === 'processing'}"
            >Sign In</sl-button>
            <sl-button
              id="join-button"
              href="/join"
              ?disabled="${this.viewState === 'processing'}"
            >Join Q1</sl-button>
          </div>

        </form>


        <!-- Social Sign-In Link -->
        <!-- <div class="social-sign-in">
          <a href="#">Sign in with LinkedIn</a>
        </div> -->
      </sl-card>
    `;
  }
}

customElements.define('sign-in-view', SignInView);
