import { html, LitElement } from 'lit';
import { q1GuildChipStyles } from './q1-guild-chip-styles.js';

export class Q1GuildChip extends LitElement {
  static styles = [ q1GuildChipStyles ];

  static properties = {
    guild: { type: Object },
    href: { type: String }
  };

  constructor() {
    super();
    this.guild = {};
    this.href = '';
  }

  render() {
    return html`
      <a class="guild-chip" href="${this.href}">
        <span class="guild-name">${this.guild.name}</span>
      </a>
    `;
  }
}

customElements.define('q1-guild-chip', Q1GuildChip);